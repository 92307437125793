body {
  margin: 0;
  font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 600px) {
  #qd {
    text-align: center;
  }
}

#join-as-baker-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/tmp/iv2.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #ffffff;
  height: 100vh;
  display: flex;
  align-items: center;
}

#saas-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/h/hero0817.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #ffffff;
  height: 100vh;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  #saas-hero {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/h/hero0817-mobile.jpg") no-repeat center center;
  }
}

/* Chrome, Safari, Edge, Opera  remove spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* stripe */
.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px;
  padding: 18px 10px;
  border: 1px solid #cacaca;
  max-height: 56px;
  width: 100%;
  background: white;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

#card-elementfocus,
#card-element.StripeElement--focus {
  border: 2px solid #78374a;
}
/* stripe */

/* image magnify */
.iiz {
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.iiz--drag .iiz__zoom-img--visible {
  cursor: -webkit-grab;
  cursor: grab;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
}

.iiz__img--hidden {
  visibility: hidden;
  opacity: 0;
}

.iiz__img--abs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#222), to(#222)),
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222));
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* image magnify */

#hero {
  background-color: #ceb4c1;
  min-height: 90vh;
}

#hero h1 {
  font-size: 3rem;
  line-height: 1.6;
  color: #333;
}

#hero .artist {
  position: relative;
  height: auto;
}

#hero .artist img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: cover;
  border-radius: 5px;
}

#hero .img-title {
  bottom: 0;
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  width: 100%;
  font-size: "1rem";
}

@media only screen and (max-width: 480px) {
  #hero h1 {
    font-size: 2rem;
  }
  #hero .artist {
    width: 100%;
    height: auto;
  }

  #hero .artist img {
    height: auto;
  }
}

#images .carousel.carousel-slider .control-arrow {
  opacity: 0.8;
}

.react-tel-input input.phone {
  width: 100%;
}

div.react-tel-input .form-control:focus {
  border-color: #78374a;
  box-shadow: 0 0 0 1px #78374a;
}

.product {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .product {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }
}

.consent .MuiTypography-body1 {
  font-size: 0.85rem;
  color: #444;
}

#images .carousel .thumbs {
  margin: 0;
  padding: 0;
}

#images .carousel .thumb {
  width: 32px;
  height: 32px;
}

#images .carousel .thumbs-wrapper {
  margin: 0;
}

.carousel .thumbs ul {
  margin: 0;
  padding: 0;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.chat-canvas {
  padding: 15px;
  flex-grow: 1;
}

.chat-bg {
  background: url("https://storage.googleapis.com/bakestreet-f55a0.appspot.com/images/bg/connect_bg.png");
  background-repeat: repeat;
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}
